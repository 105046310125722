import axios from '@/plugins/axios';

export default {
  getOrganisationAssetNotes(assetId, params = {}) {
    return axios.get(`/v1/organisation/assets/${assetId}/notes`, params);
  },
  createOrganisationAssetNote(assetId, data, config = {}) {
    return axios.post(`/v1/organisation/assets/${assetId}/notes`, data, config);
  },
  deleteOrganisationAssetNote(assetId, noteId, config = {}) {
    return axios.delete(`/v1/organisation/assets/${assetId}/notes/${noteId}`, config);
  },
};
