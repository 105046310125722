<template>
  <div class="max-w-4xl">
    <page-heading :heading="$t('edit')">
      <template v-slot:tabs>
        <tabs
          :tabs="tabs"
          :active-tab="activeTab"
          @switchTab="switchTab"
        />
      </template>
    </page-heading>

    <loading-wrapper class="mt-4">
      <div class="space-y-4" v-show="activeTab === 'basic'">
        <debug>{{ model }}</debug>
        <form-open @submit="submit">
          <div class="grid grid-cols-2 gap-4">
            <form-text
              :form-id="formId"
              :label="$t('model.asset.name')"
              form-error-id="name"
              input-id="name"
              v-model="model.name"
            />
            <form-text
              :form-id="formId"
              :label="$t('model.asset.ref')"
              form-error-id="ref"
              icon="qrcode"
              input-id="ref"
              v-model="model.ref"
            />
            <form-textarea
              :form-id="formId"
              :label="$t('model.asset.desc')"
              :required="false"
              :rows="2"
              class="col-span-full"
              form-error-id="desc"
              input-id="desc"
              v-model="model.desc"
            />
            <form-select
              :form-id="formId"
              :label="$t('model.asset.status')"
              :options="data.statuses"
              class="col-span-full"
              form-error-id="status"
              input-id="status"
              v-model="model.status"
            />
            <form-select
              :form-id="formId"
              :label="$t('model.asset.categories')"
              :multiple="true"
              :options="data.categories"
              :required="false"
              form-error-id="category_ids"
              input-id="categories"
              v-model="model.category_ids"
            />
            <form-select
              :form-id="formId"
              :label="$t('model.asset.notification_groups')"
              :multiple="true"
              :options="data.notification_groups"
              :required="false"
              form-error-id="notification_group_ids"
              input-id="notification_groups"
              v-model="model.notification_group_ids"
            />
            <div class="col-span-full flex justify-end">
              <app-button :disabled="loading" :label="$t('update')" :loading="loading"/>
            </div>
          </div>
        </form-open>
      </div>
      <div v-show="activeTab === 'media'">
        <div class="space-y-4">
          <validation-errors :form-id="formIds.createAssetMedia"/>
          <form-files
            :files="media"
            :server="{
              process: createAssetMedia,
            }"
            @processfiles="refreshModel"
            class="w-full"
            name="file"
          />
          <gallery :images="images" @deleteMedia="deleteAssetMedia"/>
        </div>
      </div>
      <div class="space-y-4 h-48" v-show="activeTab === 'location'">
        <gmap-autocomplete
          :options="{
            fields: [
              'geometry',
              'formatted_address',
            ],
          }"
          :placeholder="$t('google_places_autocomplete_placeholder')"
          @place_changed="placeAutocompleteChange"
          class="sm:text-sm appearance-none block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 focus:outline-none focus:ring-app-orange focus:border-app-orange shadow-none"
          ref="googleMapAutocomplete"
        />

        <gmap-map
          :center="googleMap.mapCenter"
          :clickable="true"
          :zoom="googleMap.zoom"
          @click="gmapClick"
          class="w-full h-full"
          ref="googleMap"
        >
          <gmap-marker
            :draggable="true"
            :key="index"
            :position="m.position"
            @dragend="gmapMarkerDragEnd"
            v-for="(m, index) in googleMap.markers"
          />
        </gmap-map>

        <!--
        <div class="flex justify-end items-center space-x-1">
          <app-svg class="h-4" svg="information-circle"/>
          <span class="text-sm text-gray-600">{{ $t('google_map_marker_help') }}</span>
        </div>
        -->

        <div class="grid grid-cols-2 gap-4">
          <form-text
            :form-id="formId"
            :label="$t('model.location.lat')"
            :required="false"
            class="hidden"
            form-error-id="location.lat"
            input-id="location.lat"
            v-model="model.location.lat"
          />
          <form-text
            :form-id="formId"
            :label="$t('model.location.lng')"
            :required="false"
            class="hidden"
            form-error-id="location.lng"
            input-id="location.lng"
            v-model="model.location.lng"
          />
          <form-textarea
            :form-id="formId"
            :label="$t('model.location.desc')"
            :required="false"
            class="col-span-full"
            form-error-id="location.desc"
            input-id="location.desc"
            v-model="model.location.desc"
          />
          <div class="col-span-full flex justify-end">
            <app-button @click="submit" :disabled="loading" :label="$t('update')" :loading="loading"/>
          </div>
        </div>
      </div>
      <template v-if="activeTab === 'notes'">
        <notes
          :form-id="formIds.createAssetNote"
          :items="notes.items"
          :meta="notes.meta"
          @delete="deleteAssetNote"
          @submit="createAssetNote"
        />
      </template>
    </loading-wrapper>
  </div>
</template>

<script>
import _ from 'lodash';
import ApiAssetCategoryService from '@/services/api/asset_category';
import ApiAssetNoteService from '@/services/api/asset_note';
import ApiAssetService from '@/services/api/asset';
import ApiAssetStatusService from '@/services/api/asset_status';
import ApiNotificationGroupService from '@/services/api/notification_group';
import Common from '@/mixins/common';
import config from '@/config';
import Gallery from '@/components/Gallery.vue';
import Notes from '@/components/Notes.vue';
import Tabs from '@/components/Tabs.vue';
import { gmapApi } from 'vue2-google-maps';

export default {
  components: {
    Gallery,
    Notes,
    Tabs,
  },
  computed: {
    tabs() {
      return [
        {
          id: 'basic',
          label: this.$t('basic'),
          icon: 'information-circle',
        },
        {
          id: 'location',
          label: this.$t('location'),
          icon: 'location-marker',
        },
        {
          id: 'media',
          label: this.$t('media'),
          icon: 'photograph',
        },
        {
          id: 'notes',
          label: this.$t('notes'),
          icon: 'pencil-alt',
        },
      ];
    },
  },
  data() {
    return {
      activeTab: 'basic',
      data: {
        categories: [],
        images: [],
        notification_groups: [],
        statuses: [],
      },
      formId: 'editOrganisationAsset',
      formIds: {
        createAssetNote: 'createAssetNote',
        createAssetMedia: 'createAssetMedia',
      },
      googleMap: {
        mapCenter: config.app.googleMapDefaults.center,
        markers: [],
        zoom: config.app.googleMapDefaults.zoom,
      },
      images: [],
      media: [],
      modelId: this.$route.params.assetId,
      model: {
        location: {},
      },
      notes: {
        items: [],
        meta: {},
      },
      query: {
        filter: {},
      },
    };
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    createAssetMedia(fieldName, file, metadata, load, error, progress, abort, transfer, options) {
      const formData = new FormData();
      formData.append('file', file, file.name);

      ApiAssetService.createOrganisationAssetMedia(this.modelId, formData, {
        formId: this.formIds.createAssetMedia,
        onUploadProgress: (e) => {
          progress(e.lengthComputable, e.loaded, e.total);
        },
      })
        .then((response) => {
          const { data } = response.data;

          load(data.uuid);
        })
        .catch(() => {});

      return {
        abort: () => {
          abort();
        },
      };
    },
    deleteAssetMedia(mediaId) {
      ApiAssetService.deleteOrganisationAssetMedia(this.modelId, mediaId)
        .then(() => {
          this.refreshModel();
        })
        .catch(() => {});
    },
    createAssetNote(data) {
      ApiAssetNoteService.createOrganisationAssetNote(this.modelId, data, {
        formId: this.formIds.createAssetNote,
      })
        .then(() => {
          this.getAssetNotes(this.modelId);
        })
        .catch(() => {});
    },
    deleteAssetNote(noteId) {
      ApiAssetNoteService.deleteOrganisationAssetNote(this.modelId, noteId)
        .then(() => {
          this.getAssetNotes(this.modelId);
        })
        .catch(() => {});
    },
    getAndSetModel(modelId) {
      ApiAssetService.getOrganisationAsset(modelId, {
        params: {
          include: [
            'categories',
            'location',
            'media',
            'notificationGroups',
            'reportsCount',
          ],
        },
      }).then((response) => {
        const { data } = response.data;

        this.model = this.resourceToModel(data);
        this.data = {
          ...this.data,
          images: data.images,
          reports_count: data.reports_count,
        };
        this.images = data.images;

        if (data.location) {
          const position = {
            lat: data.location.lat,
            lng: data.location.lng,
          };

          this.setMapMarkerAndCenter(position);
        }
      });
    },
    getAssetNotes(modelId) {
      ApiAssetNoteService.getOrganisationAssetNotes(modelId)
        .then((response) => {
          const { data, meta } = response.data;

          this.notes.items = data;
          this.notes.meta = meta;
        })
        .catch(() => {});
    },
    gmapClick(event) {
      this.setMapMarkerAndCenter({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
    },
    gmapMarkerDragEnd(event) {
      this.setMapMarkerAndCenter({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
    },
    modelToRequest() {
      const data = this.model;
      // data.location = _.compact(data.location);

      return data;
    },
    placeAutocompleteChange(place) {
      if (!place) {
        return;
      }

      this.setMapMarkerAndCenter({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        desc: place.formatted_address,
      });

      // this.$refs.googleMapAutocomplete.clear();
    },
    refreshModel() {
      this.getAndSetModel(this.modelId);
    },
    resourceToModel(resource) {
      const model = _.pick(resource, [
        'desc',
        'name',
        'ref',
        'status',
      ]);
      model.category_ids = _.map(resource.categories, 'id');
      model.notification_group_ids = _.map(resource.notification_groups, 'id');
      model.location = resource.location
        ? _.pick(resource.location, [
          'desc',
          'lat',
          'lng',
        ])
        : {};

      return model;
    },
    setMapMarkerAndCenter(position) {
      this.googleMap.mapCenter = position;
      this.googleMap.markers = [
        {
          position,
        },
      ];
      this.googleMap.zoom = 20;

      this.model.location = {
        ...this.model.location,
        ...position,
      };
    },
    submit() {
      ApiAssetService.updateOrganisationAsset(this.modelId, this.modelToRequest(), {
        formId: this.formId,
        showMessage: true,
      })
        .catch(() => {});
    },
    switchTab(tab) {
      if (tab === 'reports') {
        this.$router.push({
          name: 'asset_reports',
          query: {
            filter: {
              ref: [
                this.model.ref,
              ],
            },
          },
        });
      }

      this.activeTab = tab;
    },
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    this.$refs.googleMap.$mapPromise.then((map) => {
      console.log(map, gmapApi());
    });

    ApiAssetStatusService.getAssetStatuses()
      .then((response) => {
        const { data } = response.data;

        this.data.statuses = this.toSelectOptions(data, 'status', 'id', (label, value) => `asset_status.${value}`);
      })
      .catch(() => {});

    ApiAssetCategoryService.getAssetCategories({
      params: {
        per_page: -1,
        sort: 'name',
      },
    }).then((response) => {
      const { data } = response.data;

      this.data.categories = this.toSelectOptions(data);
    }).catch(() => {});

    ApiNotificationGroupService.getOrganisationNotificationGroups({
      params: {
        per_page: -1,
        sort: 'name',
      },
    }).then((response) => {
      const { data } = response.data;

      this.data.notification_groups = this.toSelectOptions(data);
    }).catch(() => {});

    this.getAssetNotes(this.modelId);
    this.getAndSetModel(this.modelId);
  },
  mixins: [
    Common,
  ],
};
</script>
